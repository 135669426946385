import React from 'react';
import AboutSection from '../components/home/about-section';
import CarouselSlider from '../components/home/carousel-slider';
import DonateFrom from '../components/home/donate-form';
import FooterSlider from '../components/home/footer-slider';
import Fundraiser from '../components/home/fundraiser';
import Galary from '../components/home/galary';
import MissionSection from '../components/home/mission-section';

const Home = () => {
  return (
    <>
      {/* header section start */}
      {/* header section end */}
      {/* banner section start */}
      <div className='banner_section layout_padding'>
        {/* Carousel */}
        <CarouselSlider />
        {/* DonateForm */}
        <DonateFrom />
        {/* Fundraiser */}
        <Fundraiser />
      </div>
      {/* banner section end */}
      {/* about section start */}
      <AboutSection />
      {/* about section end */}
      {/* Galary section start */}
      <Galary />
      {/* Galary section end */}
      {/* Mission Section Start */}
      <MissionSection />
      {/* news section end */}
      {/* events section start */}
      {/* <div className='events_section layout_padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <h1 className='news_taital'>FEATURED CAUSE</h1>
              <p className='news_text'>
                going to use a passage of Lorem Ipsum, you need to be sure there
                isn't anything embarrassing hidden in the middle of text.{' '}
              </p>
            </div>
          </div>
          <div className='events_section_2'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='img_7'>
                  <img src='images/img-7.png' className='img_7' />
                </div>
                <div className='date_bt'>
                  <div className='date_text active'>
                    <a href='#'>01</a>
                  </div>
                  <div className='date_text'>
                    <a href='#'>FEB</a>
                  </div>
                </div>
              </div>
              <div className='col-md-7'>
                <h1 className='give_taital_1'>The Internet tend to repeat</h1>
                <p className='ipsum_text_1'>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin
                  literatureContrary to popular belief, Lorem Ipsum is not
                  simply random text. It has roots in a piece of classical Latin
                  literature
                </p>
                <h5 className='raised_text_1'>
                  Raised: $60,010{' '}
                  <span className='goal_text'>Goal: $70,000</span>
                </h5>
                <div className='donate_btn_main'>
                  <div className='readmore_btn'>
                    <a href='#'>Read More</a>
                  </div>
                  <div className='readmore_btn_1'>
                    <a href='donate.html'>Donate Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='events_section_2'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='img_7'>
                  <img src='images/img-8.png' className='img_7' />
                </div>
                <div className='date_bt'>
                  <div className='date_text active'>
                    <a href='#'>10</a>
                  </div>
                  <div className='date_text'>
                    <a href='#'>FEB</a>
                  </div>
                </div>
              </div>
              <div className='col-md-7'>
                <h1 className='give_taital_1'>The Internet tend to repeat</h1>
                <p className='ipsum_text_1'>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin
                  literatureContrary to popular belief, Lorem Ipsum is not
                  simply random text. It has roots in a piece of classical Latin
                  literature
                </p>
                <h5 className='raised_text_1'>
                  Raised: $60,010{' '}
                  <span className='goal_text'>Goal: $70,000</span>
                </h5>
                <div className='donate_btn_main'>
                  <div className='readmore_btn'>
                    <a href='#'>Read More</a>
                  </div>
                  <div className='readmore_btn_1'>
                    <a href='donate.html'>Donate Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* events section end */}
      {/* Footer Slider */}
      <FooterSlider /> {/* donate section end */}
      {/* footer section start */}
      {/* footer section end */}
      {/* copyright section start */}
      {/* copyright section end */}
      {/* Javascript files*/}
      {/* sidebar */}
      {/* javascript */}
    </>
  );
};

export default Home;
