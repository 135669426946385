import React from 'react';

const Galary = () => {
  return (
    <div className='mission_section layout_padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <h1 className='mission_taital'> সফল মিশন</h1>
            {/* <p className='mission_text'>
                going to use a passage of Lorem Ipsum, you need to be sure there
                isn't anything embarrassing hidden in the middle of text.{' '}
              </p> */}
          </div>
        </div>
      </div>
      {/* --- */}
      <div className='mission_section_2'>
        <div className='row'>
          <div className='offset-md-2' />
          {/* Feni-relief-project-2024 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/Feni-relief-project-2024.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    ফেনি বন্যার্তদের সহায়তায় ইত্তিহাদ ফাউন্ডেশন রিলিফ প্রজেক্ট
                    ২০২৪।
                    <br></br> 🖤
                  </h4>
                  <p className='alteration_text'>
                    আমাদের প্যাকেটে যা যা থাকছে: ১- চাল ৫ কেজি ২- আলু ৩ কেজি ৩-
                    পিয়াজ ১ কেজি ৪- ডাল ১ কেজি ৫- তেল ১লিটার ৬- লবন ১ কেজি ৭-
                    মরিচের গুড়া ২০০গ্রাম ৮- হলুদের গুড়া ৫০ গ্রাম ৯- পানি ২লিটার
                    ১০- প্রয়োজনীয় ঔষধ
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Feni-relief-project-2024-2 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/Feni-relief-project-2024-2.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    ফেনি বন্যার্তদের সহায়তায় ইত্তিহাদ ফাউন্ডেশন রিলিফ প্রজেক্ট
                    ২০২৪।
                    <br></br>
                    🖤
                  </h4>
                  <p className='alteration_text'>
                    আমাদের টিম ২ হাজার মানুষের জন্য খিচুড়ি পোঁছে দিয়েছে
                    আলহামদুলিল্লাহ।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      <div className='mission_section_2'>
        <div className='row'>
          <div className='offset-md-2' />
          {/* ramadan-project-2023 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/ramadan-project-2023.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    ইত্তিহাদ ফাউন্ডেশনের রমাদান প্রজেক্ট ২০২৪।
                    <br></br> 🖤
                  </h4>
                  <p className='alteration_text'>
                    রমজান উপলক্ষে ইত্তিহাদ ফাউন্ডেশনের এর পক্ষ থেকে ২ ধাপে মোট
                    ৫০ পরিবারের মাঝে খাদ্যসামগ্রী পৌঁছে দেওয়া হলো আলহামদুলিল্লাহ
                    । যারা এইকাজে আমাদের সাথে অংশীদার হয়েছেন আল্লাহ আপনাদের
                    সাদাকা কবুল করুক। আপনাদেরকে দুনিয়া এবং আখিরাতে উত্তম
                    প্রতিদান দিক।
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Sewing-machine-self-employment-2023 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/Sewing-machine-self-employment-2023.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    ইত্তিহাদ ফাউন্ডেশনের আত্মকর্মসংস্থান প্রজেক্ট
                    <br></br>
                    🖤
                  </h4>
                  <p className='alteration_text'>
                    ইত্তিহাদ ফাউন্ডেশনের আত্মকর্মসংস্থান প্রজেক্ট এর আওতায় নূর
                    বানু আক্তারকে মোটর সিষ্টেমের সেলাই মেশিন ক্রয় করে দেওয়া হলো
                    আলহামদুলিল্লাহ । আল্লাহ আমাদের কাজগুলো কবুল করুক।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      <div className='mission_section_2'>
        <div className='row'>
          <div className='offset-md-2' />
          {/* Eye-operation-2023 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/Eye-operation-2023.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    মনির আংকেলের এর চোখের চিকিৎসা ২০২৩।
                    <br></br> 🖤
                  </h4>
                  <p className='alteration_text'>
                    মনির আংকেলের এর চোখের চিকিৎসার জন্য ইত্তিহাদ ফাউন্ডেশন এর
                    পক্ষ থেকে আট হাজার টাকা উনার স্ত্রীর হাতে তুলে দেওয়া হলো
                    আলহামদুলিল্লাহ। আল্লাহ দাতাদের দান গুলো কবুল করুক।আমীন।
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* winter-clothes-2023 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/winter-clothes-2023.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    অসহায় শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ
                    <br></br>
                    🖤
                  </h4>
                  <p className='alteration_text'>
                    আলহামদুলিল্লাহ ইত্তিহাদ ফাউন্ডেশনের পক্ষ থেকে অসহায়
                    শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ করা হয়েছে। সারা দেশে জেকে
                    বসেছে শীত। আর এতে সবচেয়ে বেশি দুর্ভোগে পড়েছেন নিম্ন আয়ের
                    মানুষ। ঠান্ডায় কাহিল এসব অসহায় ও দুস্থ মানুষদের কষ্ট কিছুটা
                    লাঘব করার প্রচেষ্টা। যারা এইকাজে আমাদের সাথে অংশীদার হয়েছেন
                    আল্লাহ আপনাদের সাদাকা কবুল করুক। আপনাদেরকে দুনিয়া এবং
                    আখিরাতে উত্তম প্রতিদান দিক। ফাউন্ডেশনের সকল ভাইদের
                    প্রচেষ্টায় প্রজেক্টেটি সফল ভাবে শেষ করলাম আলহামদুলিল্লাহ।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      <div className='mission_section_2'>
        <div className='row'>
          <div className='offset-md-2' />
          {/* Eid-ul-adha-2023 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/Eid-ul-adha-2023.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    ইত্তিহাদ ফাউন্ডেশনের কোরবান প্রজেক্ট ২০২৩। 🖤
                  </h4>
                  <p className='alteration_text'>
                    আলহামদুলিল্লাহ কোরবান উপলক্ষে ইত্তিহাদ ফাউন্ডেশনের পক্ষ থেকে
                    দিনমজুর ও অসহায় পরিবারের মাঝে গরুর মাংস পৌঁছে দেওয়া হলো।
                    যারা এইকাজে আমাদের সাথে অংশীদার হয়েছেন আল্লাহ আপনাদের সাদাকা
                    কবুল করুক। আপনাদেরকে দুনিয়া এবং আখিরাতে উত্তম প্রতিদান দিক।
                    ফাউন্ডেশনের সকল ভাইদের প্রচেষ্টায় প্রজেক্টেটি সফল ভাবে শেষ
                    করলাম আলহামদুলিল্লাহ।
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tahfizul-quran-madrasa-bali-2023 Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/Tahfizul-quran-madrasa-bali-2023.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    সীতাকুণ্ড তাহফীজুল কুরআন মাদ্রাসার মসজিদের নির্মাণ কাজের
                    জন্য বালু পৌঁছে দেওয়া হলো
                    <br></br>
                    🖤
                  </h4>
                  <p className='alteration_text'>
                    আলহামদুলিল্লাহ ইত্তিহাদ ফাউন্ডেশনের পক্ষ থেকে সীতাকুণ্ড
                    তাহফীজুল কুরআন মাদ্রাসার মসজিদের নির্মাণ কাজের জন্য বালু
                    পৌঁছে দেওয়া হলো। মসজিদের সঙ্গে মুসলিম উম্মাহর হৃদয় ও আত্মার
                    সম্পর্ক। আল্লাহর ঘর মসজিদ নির্মাণ, মেরামত, সংস্কার এবং এতে
                    দান-সাহায্য করা মুসলিমদের কাছে অত্যন্ত আবেগ ও গৌরবের বিষয়।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      <div className='mission_section_2'>
        <div className='row'>
          <div className='offset-md-2' />
          {/* Ramadan Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/ittihad_foundation_ramadan_project_01.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    ইত্তিহাদ ফাউন্ডেশনের রমাদান প্রজেক্ট। 🖤
                  </h4>
                  <p className='alteration_text'>
                    ইত্তিহাদ ফাউন্ডেশনের রমাদান প্রজেক্ট। 🖤 রমাদান মাস আমলের
                    মাস। সাদাকার মাস। তাই রমাদান মাসকে কেন্দ্র করে আমদের এই
                    ক্ষুদ্র প্রচেষ্টা। একটি পরিবারের জন্য আমাদের রামাদান
                    প্যাকেজে যা থাকছে (চাল,আলু,ডাল,তেল,পেঁয়াজ, লবণ) আমাদের
                    চারপাশে এমন অনেক দিন মজুর মানুষ আছে যাদের ইনকাম অনেক সীমিত।
                    এ আয়ে কয়েক সদস্যের পরিবার কিভাবে চলে আমাদের মস্তিষ্কের ধারণ
                    ক্ষমতার বাহিরে। এখন দেশের বর্তমান বাজারের যে অবস্থা তাতে
                    তাদের কি পরিমাণ শোচনীয় অবস্থা একটু চিন্তা করা উচিত। অনেকে
                    লজ্জায় বলতেও পারেনা কারো কাছে চাইতেও পারেনা। তাই আমদের এই
                    ক্ষুদ্র প্রচেষ্টা।
                  </p>
                  {/* <div className='btn_main'>
                       <div className='donate_bt'>
                        <a href='#'>Read More</a>
                      </div> 
                       <div className='donate_bt'>
                        <a href='donate.html'>Donate Now</a>
                      </div> 
                    </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* Winter Project Galary */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/galary_2.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    অসহায় শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ
                  </h4>
                  <p className='alteration_text'>
                    আলহামদুলিল্লাহ আল-ইত্তিহাদ ফাউন্ডেশনের পক্ষ থেকে অসহায়
                    শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ করা হয়েছে। সারা দেশে জেকে
                    বসেছে শীত। আর এতে সবচেয়ে বেশি দুর্ভোগে পড়েছেন নিম্ন আয়ের
                    মানুষ। ঠান্ডায় কাহিল এসব অসহায় ও দুস্থ মানুষদের কষ্ট কিছুটা
                    লাঘব করার প্রচেষ্টা।
                  </p>
                  {/* <div className='btn_main'>
                      <div className='donate_bt'>
                        <a href='#'>Read More</a>
                      </div>
                      <div className='donate_bt'>
                        <a href='donate.html'>Donate Now</a>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---- */}
      <div className='mission_section_2'>
        <div className='row'>
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/galary_4.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    অসহায় শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ
                  </h4>
                  <p className='alteration_text'>
                    আলহামদুলিল্লাহ আল-ইত্তিহাদ ফাউন্ডেশনের পক্ষ থেকে অসহায়
                    শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ করা হয়েছে। সারা দেশে জেকে
                    বসেছে শীত। আর এতে সবচেয়ে বেশি দুর্ভোগে পড়েছেন নিম্ন আয়ের
                    মানুষ। ঠান্ডায় কাহিল এসব অসহায় ও দুস্থ মানুষদের কষ্ট কিছুটা
                    লাঘব করার প্রচেষ্টা।
                  </p>
                  {/* <div className='btn_main'>
                      <div className='donate_bt'>
                        <a href='#'>Read More</a>
                      </div>
                      <div className='donate_bt'>
                        <a href='donate.html'>Donate Now</a>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/galary_8.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    অসহায় শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ
                  </h4>
                  <p className='alteration_text'>
                    আলহামদুলিল্লাহ আল-ইত্তিহাদ ফাউন্ডেশনের পক্ষ থেকে অসহায়
                    শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ করা হয়েছে। সারা দেশে জেকে
                    বসেছে শীত। আর এতে সবচেয়ে বেশি দুর্ভোগে পড়েছেন নিম্ন আয়ের
                    মানুষ। ঠান্ডায় কাহিল এসব অসহায় ও দুস্থ মানুষদের কষ্ট কিছুটা
                    লাঘব করার প্রচেষ্টা।
                  </p>
                  {/* <div className='btn_main'>
                      <div className='donate_bt'>
                        <a href='#'>Read More</a>
                      </div>
                      <div className='donate_bt'>
                        <a href='donate.html'>Donate Now</a>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='offset-md-2' />
        </div>
      </div>
      {/* ----- */}
      <div className='mission_section_2'>
        <div className='row'>
          <div className='offset-md-2' />
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/galary_6.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>
                    অসহায় শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ
                  </h4>
                  <p className='alteration_text'>
                    আলহামদুলিল্লাহ আল-ইত্তিহাদ ফাউন্ডেশনের পক্ষ থেকে অসহায়
                    শীতার্তদের মধ্যে শীতবস্ত্র বিতরণ করা হয়েছে। সারা দেশে জেকে
                    বসেছে শীত। আর এতে সবচেয়ে বেশি দুর্ভোগে পড়েছেন নিম্ন আয়ের
                    মানুষ। ঠান্ডায় কাহিল এসব অসহায় ও দুস্থ মানুষদের কষ্ট কিছুটা
                    লাঘব করার প্রচেষ্টা।
                  </p>
                  {/* <div className='btn_main'>
                      <div className='donate_bt'>
                        <a href='#'>Read More</a>
                      </div>
                      <div className='donate_bt'>
                        <a href='donate.html'>Donate Now</a>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Education Project Galaray */}
          <div className='col-md-5'>
            <div className='container_main'>
              <img
                src='images/galary/galary_1.jpg'
                alt='Avatar'
                className='image'
              />
              <div className='overlay'>
                <div className='text'>
                  <h4 className='some_text'>রফিক আংকেল আমাদের প্রথম পেশেন্ট</h4>
                  <p className='alteration_text'>
                    রফিক আংকেল আমাদের প্রথম পেশেন্ট যার মাধ্যমে আল-ইত্তিহাদ
                    ফাউন্ডেশন এর কাজ আনুষ্ঠানিকভাবে শুরু করলাম আলহামদুলিল্লাহ।
                    পেশেন্ট এর ছেলে তারেক আমাদের খুব কাছের একজন ছোট ভাই।এইবার
                    এস.এস.সি পরীক্ষার্থী।এইবয়সে এখন ওরে কাজ করতে হইতেছে পরিবারের
                    জন্য।কারন ওর বাবা ওর পরিবারে একমাত্র উপার্জন করা ব্যক্তি।তাই
                    বাবার অবর্তমানে পরিবারের দেখাশোনার দায়িত্বতো তার। আজকে যখন
                    চট্টগ্রাম মেডিকেলে রফিক আংকেলকে দেখতে গেলাম উনি আমারে বলে
                    আমি যতদিন সুস্থ ছিলাম আমার ছেলেকে একটা কাজও করতে দি নাই,আর
                    এখন? বাবারা আসলেই সন্তানের জন্য একটা শক্তিশালী পাহাড়।
                    সূর্যের তাপ আর বাপের গরম দুইটার সহ্য করা ভালো,কারন দুইটায়
                    ডুবে গেলে জীবন অন্ধকার হয়ে যায়। ছেলে হিসেবে তারেক খুব
                    আন্তরিক একটা ছেলে।সবাইকে সব সময় হেল্প করার চেষ্টা করে।কিন্তু
                    ওর বিপদে আমরা তেমন কিছু একটা করতে পারলাম না। তাও আপনাদের
                    সবার প্রচেষ্টায় রফিক আংকেলের চোখের অপরেশন এর জন্য ছোট একটা
                    অর্থসহায়তা দিতে সক্ষম হয়েছি। আল্লাহ রফিক আংকেলকে দ্রুত শেফা
                    দান করুক। আল্লাহ সবার প্রচেষ্টা কবুল করুক।
                  </p>
                  {/* <div className='btn_main'>
                       <div className='donate_bt'>
                        <a href='#'>Read More</a>
                      </div> 
                       <div className='donate_bt'>
                        <a href='donate.html'>Donate Now</a>
                      </div> 
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galary;
