import React from 'react';
import ScholarshipMission from './missions/scholarship-mission';
import RamadanMission from './missions/ramadan-mission';

const MissionSection = () => {
  return (
    <div className='news_section layout_padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <h1 className='news_taital'>পরবর্তী মিশন</h1>
            {/* <p className='news_text'>
                going to use a passage of Lorem Ipsum, you need to be sure there
                isn't anything embarrassing hidden in the middle of text.{' '}
              </p> */}
          </div>
        </div>
        {/* Missions */}
        {/* <RamadanMission /> */}
        <ScholarshipMission />
      </div>
    </div>
  );
};

export default MissionSection;
