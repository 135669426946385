import React, { useState, useEffect } from 'react';

const CarouselSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const carouselData = [
    {
      title: 'যাকাত আদায় করুন',
      text: 'আল্লাহ তায়ালা বলেন, তোমরা যেকোনো ভালো জিনিস ব্যয় করো, নিশ্চয়ই আল্লাহ তাআলা সে বিষয়ে অবগত আছেন।’(২:২৭৩)',
    },
    {
      title: 'যাকাত আদায় করুন',
      text: 'এবং তোমরা যারা আল্লাহর রাস্তায় দান করবে উহার প্রতিদান তোমাদেরকে পুরাপুরি দেয়া হবে। আর তোমাদের প্রতি কোনপ্রকার জুলুম করা হবে না। (সুরা আনফাল, আয়াত-৬০ ',
    },
    {
      title: 'যাকাত আদায় করুন',
      text: 'যে ব্যক্তি দুনিয়ায় অপরের একটি প্রয়োজন মিটিয়ে দেবে, পরকালে আল্লাহ্ তার ১০০ প্রয়োজন পূরণ করে দেবেন এবং বান্দার দুঃখ-দুর্দশায় কেউ সহযোগিতার হাত বাড়ালে আল্লাহ তার প্রতি করুণার দৃষ্টি দেন । -(মুসলিম, হাদিস : ২৫৬৬) ',
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % carouselData.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [currentIndex, carouselData.length]);

  return (
    <div className='container margin_bottom_120'>
      <div
        id='carouselExampleIndicators'
        className='carousel slide'
        data-ride='carousel'
      >
        <ol className='carousel-indicators'>
          {carouselData.map((data, index) => (
            <li
              key={index}
              data-target='#carouselExampleIndicators'
              data-slide-to={index}
              className={index === currentIndex ? 'active' : ''}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </ol>
        <div className='carousel-inner'>
          {carouselData.map((item, i) => (
            <div
              className={`carousel-item ${currentIndex === i ? 'active' : ''}`}
              key={i}
            >
              <div className='row'>
                <div className='col-sm-12'>
                  <h1 className='banner_taital'>{item.title} </h1>
                  <p className='banner_text'>{item.text}</p>
                  {/* <div className='read_bt'>
                    <a href='#'>Read More</a>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
// const Carousel = () => {
//   return (
//     <div className='container'>
//       <div
//         id='carouselExampleIndicators'
//         className='carousel slide'
//         data-ride='carousel'
//       >
//         <ol className='carousel-indicators'>
//           <li
//             data-target='#carouselExampleIndicators'
//             data-slide-to={0}
//             className='active'
//           />
//           <li data-target='#carouselExampleIndicators' data-slide-to={1} />
//           <li data-target='#carouselExampleIndicators' data-slide-to={2} />
//           <li data-target='#carouselExampleIndicators' data-slide-to={3} />
//           <li data-target='#carouselExampleIndicators' data-slide-to={4} />
//         </ol>
//         <div className='carousel-inner'>
//           <div className='carousel-item active'>
//             <div className='row'>
//               <div className='col-sm-12'>
//                 <h1 className='banner_taital'>যাকাত আদায় করুন </h1>
//                 <p className='banner_text'>
//                   আল্লাহ তায়ালা বলেন, তোমরা যেকোনো ভালো জিনিস ব্যয় করো, নিশ্চয়ই
//                   আল্লাহ তাআলা সে বিষয়ে অবগত আছেন।’(২:২৭৩)
//                 </p>
//                 <div className='read_bt'>
//                   <a href='#'>Read More</a>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className='carousel-item'>
//             <div className='col-sm-12'>
//               <h1 className='banner_taital'>Help Poor Child</h1>
//               <p className='banner_text'>
//                 Suffered alteration in some form, by injected humour, or
//                 randomised words which don't look even slightly believable. If
//                 you are going{' '}
//               </p>
//               <div className='read_bt'>
//                 <a href='#'>Read More</a>
//               </div>
//             </div>
//           </div>
//           <div className='carousel-item'>
//             <div className='col-sm-12'>
//               <h1 className='banner_taital'>Help Poor Child</h1>
//               <p className='banner_text'>
//                 Suffered alteration in some form, by injected humour, or
//                 randomised words which don't look even slightly believable. If
//                 you are going{' '}
//               </p>
//               <div className='read_bt'>
//                 <a href='#'>Read More</a>
//               </div>
//             </div>
//           </div>
//           <div className='carousel-item'>
//             <div className='col-sm-12'>
//               <h1 className='banner_taital'>Help Poor Child</h1>
//               <p className='banner_text'>
//                 Suffered alteration in some form, by injected humour, or
//                 randomised words which don't look even slightly believable. If
//                 you are going{' '}
//               </p>
//               <div className='read_bt'>
//                 <a href='#'>Read More</a>
//               </div>
//             </div>
//           </div>
//           <div className='carousel-item'>
//             <div className='col-sm-12'>
//               <h1 className='banner_taital'>Help Poor Child</h1>
//               <p className='banner_text'>
//                 Suffered alteration in some form, by injected humour, or
//                 randomised words which don't look even slightly believable. If
//                 you are going{' '}
//               </p>
//               <div className='read_bt'>
//                 <a href='#'>Read More</a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default CarouselSlider;
