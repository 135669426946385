import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const DonateFrom = () => {
  const SERVICE_ID = 'service_e027jzp';
  const TEMPLATE_ID = 'template_piwt7gm';
  const PUBLIC_KEY = 'y1YP2mAlaJAFSqeVM';
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      amount: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, 'Must be 15 characters or less')
        .required('Required'),
      phone: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),
      amount: Yup.number()
        .typeError('you must specify a number')
        .min(0, 'Min value 0.')
        .required('Required'),
    }),
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
      // toast.info('ধন্যবাদ। খুব শিগ্রই আপনার সাথে যোগাযোগ করা হবে।');
      // formik.resetForm();
      // console.log(values);
      emailjs.send(SERVICE_ID, TEMPLATE_ID, values, PUBLIC_KEY).then(
        (result) => {
          //   console.log(result);
          toast.info('ধন্যবাদ। খুব শিগ্রই আপনার সাথে যোগাযোগ করা হবে।');
          formik.resetForm();
        },
        (error) => {
          console.log(error);
        }
      );
    },
  });

  const handlePhoneChange = (event) => {
    const result = event.target.value.replace(/\D/g, '');

    formik.setFieldValue('phone', result);
  };

  const handleAmountChange = (event) => {
    const result = event.target.value.replace(/\D/g, '');

    formik.setFieldValue('amount', result);
  };

  return (
    <div className='donation_box'>
      <form onSubmit={formik.handleSubmit}>
        <h4 className='donation_taital'>অনুদানের জন্য যোগাযোগ করুন</h4>
        <div className='mail_box'>
          <input
            type
            className='mail_text'
            placeholder='আপনার নাম'
            name='name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          <input
            type
            className='mail_text'
            placeholder='মোবাইল নাম্বার '
            name='phone'
            onChange={handlePhoneChange}
            onBlur={handlePhoneChange}
            value={formik.values.phone}
          />
          <input
            type
            className='mail_text'
            placeholder='অনুদানের পরিণাম '
            name='amount'
            onChange={handleAmountChange}
            onBlur={handleAmountChange}
            value={formik.values.amount}
          />
        </div>
        <div className='send_bt'>
          <button disabled={!formik.isValid} type='submit'>
            অনুদান করুন
          </button>
        </div>
      </form>
    </div>
  );
};

export default DonateFrom;
