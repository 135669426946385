import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showClass, setShowClass] = useState(false);

  const handleToggle = () => {
    setShowClass(!showClass);
  };
  return (
    <div className='header_section'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <a className='navbar-brand text-center'>
          <Link to={`/`}>
            <img src='images/galary/logo.jpg' />
          </Link>
        </a>
        <button
          onClick={handleToggle}
          className={`navbar-toggler ${!showClass ? 'collapsed' : ''}`}
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>
        <div
          className={`collapse navbar-collapse ${showClass ? 'show' : ''}`}
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item active'>
              <Link className='nav-link' to={`/`}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={`/about-us`}>
                About Us
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={`/upcoming-mission`}>
                Upcoming Mission
              </Link>
            </li>
            {/* <li className='nav-item'>
              <a className='nav-link' href='contact.html'>
                Contact Us
              </a>
            </li> */}
            <li className='nav-item'>
              <Link className='nav-link' to={`/successful-missions`}>
                Successful Missions
              </Link>
            </li>
          </ul>
          <form className='form-inline my-2 my-lg-0'>
            {/* <div className='search_icon'>
              <a href='#'>
                <img src='images/search-icon.png' />
              </a>
            </div> */}
            <Link to={'/donate'} className='donate_btn' type='submit'>
              Donate Now
            </Link>
          </form>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
