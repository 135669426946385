import React from 'react';

const FooterSlider = () => {
  return (
    <>
      <div className='donate_section layout_padding'>
        <div className='container'>
          {/* <h1 className='donate_taital'>Donat pepole Says </h1> */}
          <div className='donate_taital_main'>
            <div
              id='main_slider'
              className='carousel slide'
              data-ride='carousel'
            >
              <div className='carousel-inner'>
                <div className='carousel-item active'>
                  {/* <div className='donate_left'>
                    <div className='client_img'>
                      <img src='images/client-img.png' />
                    </div>
                  </div> */}
                  <div className='donate_right'>
                    <h3 className='client_name_text'>
                      সাদাকা কখনো আটকে থাকা রিযিক নামিয়ে আনে, কখনো ঝুলে থাকা
                      বিপদ দূর করে। কখনো লেগে থাকা রোগ নিরাময় করে। কখনো
                      পাপমোচন বা শাপমোচন করে
                    </h3>
                    <p className='dummy_text'></p>
                  </div>
                </div>
                <div className='carousel-item'>
                  <div className='donate_left'>
                    <div className='client_img'>
                      <img src='images/client-img.png' />
                    </div>
                  </div>
                  <div className='donate_right'>
                    <h3 className='client_name_text'>Mardo Merk</h3>
                    <p className='dummy_text'>
                      If you are going to use a passage of Lorem Ipsum, you need
                      to be sure there isn't anything embarrassing hidden in the
                      middle of text. All the Lorem Ipsum generators on the
                      Internet tend to repeat predefinedIf you are going to use
                      a passage of Lorem Ipsum, you need to be sure there isn't
                      anything embarrassing hidden in the middle of text. All
                      the Lorem Ipsum generators on the Internet tend to repeat
                      predefined
                    </p>
                  </div>
                </div>
                <div className='carousel-item'>
                  <div className='donate_left'>
                    <div className='client_img'>
                      <img src='images/client-img.png' />
                    </div>
                  </div>
                  <div className='donate_right'>
                    <h3 className='client_name_text'>Mardo Merk</h3>
                    <p className='dummy_text'>
                      If you are going to use a passage of Lorem Ipsum, you need
                      to be sure there isn't anything embarrassing hidden in the
                      middle of text. All the Lorem Ipsum generators on the
                      Internet tend to repeat predefinedIf you are going to use
                      a passage of Lorem Ipsum, you need to be sure there isn't
                      anything embarrassing hidden in the middle of text. All
                      the Lorem Ipsum generators on the Internet tend to repeat
                      predefined
                    </p>
                  </div>
                </div>
              </div>
              {/* <a
            className='carousel-control-next'
            href='#main_slider'
            role='button'
            data-slide='next'
          >
            <i className='fa fa-angle-right' />
          </a>
          <a
            className='carousel-control-prev'
            href='#main_slider'
            role='button'
            data-slide='prev'
          >
            <i className='fa fa-angle-left' />
          </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterSlider;
