import React from 'react';
import DonateFrom from '../components/home/donate-form';

const Donate = () => {
  return (
    <div>
      <div style={{ marginTop: '30px' }}></div>
      <DonateFrom />
    </div>
  );
};

export default Donate;
