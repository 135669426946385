import React from 'react';

const ScholarshipMission = () => {
  return (
    <div className='news_section_2'>
      <div className='row'>
        <div className='col-md-6 align-self-center'>
          <div className='news_img'>
            <img src='images/mision/ittihad-foundation-scholarship.jpg' />
          </div>
        </div>
        <div className='col-md-6'>
          <h1 className='give_taital'>ইত্তিহাদ ফাউন্ডেশন মেধা বৃত্তি -২০২৪</h1>
          <p className='ipsum_text'>
            ইত্তিহাদ ফাউন্ডেশন এর পক্ষ থেকে একটা মেধা বৃত্তি আয়োজন করা হয়েছে।
            বৃত্তি পরীক্ষায় বিজয়ীদের জন্য আকর্ষনীয় পুরস্কার থাকবে ইন-শা-আল্লাহ্।
            এবং আমরা সকল অংশগ্রহণকারীদের জন্যও শুভেচ্ছা পুরুষ্কার বিতরণ করবো
            ইন-শা-আল্লাহ্।
            <br />
            <br />
            ❑ ইত্তিহাদ ফাউন্ডেশন বৃত্তিপরীক্ষা-২০২৪ সংক্রান্ত তথ্যাবলীঃ
            <br /> <br />
            🗓️ ফরম সংগ্রহ ও জমা দেওয়ার শেষ তারিখ : ২৫ অক্টোবর '২৪।
            <br /> স্থান- পুরাতন বই ঘর, হারুন কম্পিউটার, ও চৌধুরী লাইব্রেরি।
            <br />
            🗓️ পরীক্ষার তারিখ: ০১ নভেম্বর '২৪।
            <br />
            🗞️ বৃত্তি-পরীক্ষার রেজিস্ট্রেশন ফি ১০০৳ মাত্র <br />➠ বিস্তারিত
            জানতে পুরাতন বইঘরে আমাদের প্রতিনিধির সাথে যোগাযোগ করুন এবং চোখ রাখুন
            আমাদের ফেসবুক পেইজে।
          </p>
          <h5 className='raised_text'>Fee: ৳100</h5>
          <div className='donate_btn_main'>
            {/* <div className='readmore_btn'>
              <a href='#'>Read More</a>
            </div> */}
            <div className='readmore_btn_1'>
              <a target='_blank' href='https://forms.gle/GSL1nrqGSKkjqgmVA'>
                অনলাইনে আবেদন করুন
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipMission;
