import React from 'react';

const AboutSection = () => {
  return (
    <div className='about_section layout_padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-8'>
            <h2 className='about_taital'>ইত্তিহাদ ফাউন্ডেশন</h2>
            <p className='about_text'>
              বিসমিল্লাহির রাহমানির রাহীম' "আস্সালামু আলাইকুম ওয়া রাহমাতুল্লাহ"!
              "ইত্তিহাদ ফাউন্ডেশন" এ আপনাকে স্বাগতম। এটি একটি মানবসেবামূলক
              প্রতিষ্ঠান। ফাউন্ডেশন টির মূল উদ্দেশ্য সামাজিক কল্যাণমূলক কাজ করা।
              (এটি একটি অরাজনৈতিক ও সামাজিক সংস্থা) আল্লাহ তায়ালা বলেন, তোমরা
              যেকোনো ভালো জিনিস ব্যয় করো, নিশ্চয়ই আল্লাহ তাআলা সে বিষয়ে অবগত
              আছেন।’(২:২৭৩)
            </p>
            <div className='readmore_bt'>
              <a
                href='https://www.facebook.com/ittihadfoundation/'
                target={'_blank'}
                rel='noreferrer'
              >
                Read more
              </a>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='about_img'>
              <img src='images/galary/title.jpg' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
