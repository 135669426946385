import React from 'react';

const Fundraiser = () => {
  return (
    <div className='fundraise_section'>
      <div className='fundraise_section_main'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='box_main'>
              <div className='icon_1'>
                <img src='images/icon-1.png' />
              </div>
              <h4 className='volunteer_text'>VOLUNTEERS</h4>
              {/* <p className='lorem_text'>
                    Lorem Ipsum generators on the Internet tend to repeat
                    predefined
                  </p> */}
              <div className='join_bt'>
                <a
                  href='https://www.facebook.com/groups/619657202281234'
                  target='_blank'
                  rel='noreferrer'
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='box_main active'>
              <div className='icon_1'>
                <img src='images/icon-2.png' />
              </div>
              <h4 className='volunteer_text'>FUNDRAISE</h4>
              {/* <p className='lorem_text'>
                    Lorem Ipsum generators on the Internet tend to repeat
                    predefined
                  </p> */}
              <div className='join_bt'>
                <a
                  href='https://www.facebook.com/groups/619657202281234'
                  target='_blank'
                  rel='noreferrer'
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='box_main'>
              <div className='icon_1'>
                <img src='images/icon-3.png' />
              </div>
              <h4 className='volunteer_text'>DONATION</h4>
              {/* <p className='lorem_text'>
                    Lorem Ipsum generators on the Internet tend to repeat
                    predefined
                  </p> */}
              <div className='join_bt'>
                <a
                  href='https://www.facebook.com/groups/619657202281234'
                  target='_blank'
                  rel='noreferrer'
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fundraiser;
