import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer_section layout_padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-3'>
            <div className='footer_logo'>
              <img src='images/galary/logo.jpg' />
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3'>
            <h4 className='footer_taital'>NAVIGATION</h4>
            <div className='footer_menu_main'>
              <div className='footer_menu_left'>
                <div className='footer_menu'>
                  <ul>
                    <li>
                      <Link to={`/`}>Home</Link>
                    </li>
                    <li>
                      <Link to={`/upcoming-mission`}>Upcoming Mission</Link>
                    </li>
                    <li>
                      <Link to={'/donate'}>Donate Now</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='footer_menu_right'>
                <div className='footer_menu'>
                  <ul>
                    <li>
                      <Link to={`/about-us`}>About Us</Link>
                    </li>
                    <li>
                      <Link to={`/successful-missions`}>
                        Successful Missions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3'>
            <h4 className='footer_taital'>About Us</h4>
            <p className='footer_text'>এটি একটি মানবসেবামূলক প্রতিষ্ঠান।</p>
            <p className='footer_text'>
              ফাউন্ডেশন টির মূল উদ্দেশ্য সামাজিক কল্যাণমূলক কাজ করা।
            </p>
            <p className='footer_text'>এটি একটি অরাজনৈতিক ও সামাজিক সংস্থা)</p>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3'>
            <h4 className='footer_taital'>address</h4>
            <p className='footer_text'>Sitakunda, Chattogram</p>
            <p className='footer_text'>+88 01743248386</p>
            <p className='footer_text'>ittihadyouth@gmail.com</p>
          </div>
        </div>
        <div className='footer_section_2'>
          <div className='row'>
            <div className='col-sm-4 col-md-4 col-lg-3'>
              <div className='social_icon'>
                <ul>
                  <li>
                    <a
                      href='https://www.facebook.com/ittihadfoundation/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src='images/fb-icon.png' />
                    </a>
                  </li>
                  {/* <li>
                      <a href='#'>
                        <img src='images/twitter-icon.png' />
                      </a>
                    </li> */}
                  {/* <li>
                      <a href='#'>
                        <img src='images/linkedin-icon.png' />
                      </a>
                    </li> */}
                  <li>
                    <a
                      href='https://www.instagram.com/ittihad_foundation/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src='images/instagram-icon.png' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-sm-8 col-md-8 col-lg-9'>
              <input
                type='text'
                className='address_text'
                placeholder='Enter your Enail'
                name='text'
              />
              <button type='button' className='get_bt'>
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
